<template>
  <div>
    <b-container fluid>
      <div class="pagdiv">
        <span class="pageTitle">外來監控資訊</span>
      </div>

      <b-spinner
        v-if="!hasGotForeignList && !foreignFactoryList.length"
        variant="secondary"
        class="mx-3 my-4"
      />

      <b-alert
        v-if="hasGotForeignList && !foreignFactoryList.length"
        show
        variant="secondary"
        class="text-center mx-2 my-4"
        >目前暫無資料
      </b-alert>

      <div v-else class="mx-2 my-4 listWrap">
        <div
          v-for="{ factoryId, factory } in foreignFactoryList"
          :key="factoryId"
          class="border py-2 px-3 mb-2 rounded listItem"
          @click="goToSingleSiteInfo(factoryId, factory)"
        >
          <span class="text-secondary">{{ factoryId }}</span
          >&emsp;
          {{ factory }}
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import i18n from '../lang/lang'
import { BSpinner, BAlert } from 'bootstrap-vue'

export default {
  components: { BSpinner, BAlert },
  data () {
    return {}
  },
  computed: {
    ...mapState([
      'roleComponentMenu',
      'hasGotForeignList',
      'foreignFactoryList'
    ])
  },
  methods: {
    ...mapActions(['asyncForeignFactoryList']),
    goToSingleSiteInfo (fid, siteName) {
      const params = {
        siteName,
        invLen: 0,
        pvLen: 0,
        anemometerLen: 0,
        lang: i18n.locale,
        fid,
        demo: 'false',
        foreign: 'true'
      }
      if (this.roleComponentMenu.includes('investmentanalysis')) {
        this.$router.push({
          path: `/${i18n.locale}/siteinfo/investmentanalysis`,
          query: params
        })
      } else {
        this.$swal('權限不足')
        this.$router.push({
          path: `/${i18n.locale}/realtime`
        })
      }
    }
  },
  async mounted () {
    if (!this.hasGotForeignList && !this.foreignFactoryList.length) {
      await this.asyncForeignFactoryList()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/global.scss";

.listWrap {
  column-width: 340px;
  column-gap: 30px;
}

.listItem {
  cursor: pointer;
  font-size: 15px;

  transition-duration: 0.2s;

  &:hover {
    background-color: #eee;
  }

  &:active {
    background-color: #eee;
  }
}
</style>
